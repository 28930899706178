.netlegend {
    margin-bottom: 4em;

    dl {
        & > a {
            position: relative;
            transform: translateY(-80px);
            display: block;

        }
    }


    dt {
        border-top: 1px solid #cecece;
        padding-top: 2em;

  a {
            display: block;
            position: relative;
            z-index: 1;
        }
        
        h2,
        h3,
        h4 {
            margin: 0;
            margin-bottom: .1em;
            color: #000;
            font-weight: bold;
            max-width: calc(100% - 20px);
            a {
                font-size: 1em;
                font-weight: bold;
            }
        }
        p {
            margin: .3em 0 0;
            font-size: 1.5em;
            color: #333;
        }

    }

    dd {
        margin: 0 0 2em;
        padding: 0;
        background-color: #fff;
        color: #000;
        overflow: hidden;
        font-size: 1em;


        div {
            padding-top: 1em;

            p {
                margin: 0 0 .8em 0;
                font-size: 1.8em;
                max-width: 75ch;
            }
        }


        .media {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2em;

            figure {
                flex: 0 1 auto;
                margin: 0 1em 0 0;
                padding: 0;
                min-width: 300px;

                figcaption {
                    margin-top: .5em;
                    font-size: 1.4em;
                    color: #aaa;
                }
            }
        }


        ul {
            list-style: none;
            margin: 0 0 1em 1.25em;
            padding: 0;

            li {
                margin-bottom: .5em;
                position: relative;
            }
        }

        > ul:first-child {
            margin-top: 1em;
        }

    }

    dl.boxAccordion dd {
        max-height: 0;
        transition: max-height 500ms ease;
    }

    dl.boxAccordion {
        max-height: inherit;
    }

    a:target + dt + dd div.txt {
        max-height: inherit;
        &:after,
        a.more {
            display: none;
        }
    }

    a:target + dt:before {
        transform: rotate(90deg);
    }
    a:target + dt:after {
        transform: rotate(180deg);
    }

    a:target + dt + dd {
        max-height: 2000px;
    }

    .register {
        display: flex;
        margin: 0 0 1em 0;
        padding: 0;
        list-style: none;

        li {
            flex: 0 0 auto;
            padding: 0;
            margin-right: 4px;

            &:before {
                content: none;
            }

            a {
                border: 1px solid $hoverPrimary;
                display: inline-block;
                font-weight: bold;
                line-height: 1.2em;
                padding: .2em .4em;
                text-transform: uppercase;
                transition: background 200ms;
                &:hover {
                    background-color: $linkPrimary;
                    color: #fff;
                }

            }
        }
    }

    ul.anchor {
        list-style: none;
        margin: 0 0 2em;
        padding: 0;
        margin-bottom: 3em;

        li {
            margin-bottom: .1em;
            position: relative;
        }
    }

    ul a[href^="#"],
    dt a[href^="#"],
    .related a[href^="#"] {
        &:after {
            content: none;
        }
    }

    .rating {
        display: flex;
        width: 100%;
        margin-bottom: 1em;

        a {
            border-radius: 5px;
            border: 1px solid $linkPrimary;
            display: inline-block;
            font-size: 1.4em;
            margin-right: 1em;
            padding: .3em .5em;
            text-align: center;
        }

    }

    .related {
        margin-bottom: 2em;
        font-size: 1.6em;

        a {
            font-size: 1em;
        }
    }

}
