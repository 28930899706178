/************************************************
* .newsList
*************************************************/

.newsList.listing {
	@include span(full);
	margin-bottom: 3rem;

	.f3-widget-paginator {
		@include span(full);
		text-align: center;
		li {
			display: inline-block;

			&:before{
				content: '';
			}
		}
	}

	article {
		@include span (full);
		margin-bottom: 3rem;
		border-bottom: 1px #f7f7f7 solid;
		padding-bottom: 4rem;

		figure {
			@include span(5 of 12);
			margin: 0;
			img{
				display: block;
			}
		}

		.text {
			@include span(7 of 12 last);
			padding-left: 2rem;

			time {
				color: $textPrimary;
				font-size: 1.6rem;
			}

			h3 {
				color:$first;
				margin-top:1rem;
			}

			p {
				color:$textPrimary;
				margin-bottom: 3rem;
				padding-right:1rem;
			}

			.more-btn {
				text-transform: uppercase;
				float:right;
			}
		}
		
		a.button {
			float: right;

			&:hover {
				background-color: $hoverPrimary;
				color: white;
			}
		}

	}

}

.newsList.start {
	@include span(full);
	margin-bottom: 3rem;

	h2 {
		text-align: center;
	}

	article {
		@include span (3 of 12);
		&:nth-child(4) {
			@include span (3 of 12 last);
		}
	}

	a {
		display: block;
		@media (min-width: 1025px) {
			&:hover{
				.image img{
					opacity: 0.7;
				}
			}
		}
	}

	.image {
		position: relative;

		img{
			display: block;
			transition: opacity 0.2s ease-in;
		}

		time {
			background-color: #333;
			padding: 1rem 1.5rem;
			display: inline-block;
			color: white;
			text-align: center;
			position: absolute;
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: .1rem;
			position: absolute;
			left: 1rem;
			top: 1rem;
			span{
				display: block;
			}
		}


	}

}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width:1100px) {

		.newsList.listing {

			article{

				figure {
					@include span(4 of 12);
				}

				.text {
					@include span(8 of 12 last);
				}
			}
		}


		.newsList.start {

			article {
				@include span (6 of 12);
				&:nth-child(2n) {
					@include span (6 of 12 last);
				}
			}
		}
	}

	@media (max-width:900px) {

		.newsList.listing {

			article{

				figure {
					@include span(full);
					margin-bottom: 2rem;
				}

				.text {
					@include span(full);
					padding-left: 0;
				}

			}

		}

	}

	@media (max-width:580px) {

		.newsList.start {

			article {
				@include span (full);
				&:nth-child(2n) {
					@include span (full);
				}
			}

		}

	}