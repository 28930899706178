/************************************************
* Logoauflistung (Text / Media - Layout 180)
*************************************************/

	.logoList {
		@include span(full);
		margin-bottom: 3rem;
		text-align: center;
		border-top: 1px solid $light-grey;
		padding-bottom:2rem;


		.logos{
			@include span(full);
		}

		h1, h2, h3 {
			text-transform: uppercase;
		}

		.logo{
			display: inline-block;
			margin: 0 2.5%;
		}

	} 


/*****************
* RESPONSIVE 
******************/