/************************************************
* Überschrift linksbündig (Text / Media - Layout 150)
*************************************************/

	.headlineLeft {
		@include span (7 of 12);
		margin-bottom: 3rem;
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.headlineLeft {
			@include span (10 of 12);
		}
	}

	@media (max-width: 1024px) {
		.headlineLeft {
			@include span (full);
		}
	}