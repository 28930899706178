/************************************************
* Galerie (Text / Media - Layout 170)
*************************************************/

	.gallery {
		@include span (7 of 12);
		margin-bottom: 3rem;

		.boxes {
			@include span (full);
		}

		.box {
			@include span (3 of 12);
			margin-bottom: 2rem;

			&:nth-of-type(4n) {
				@include span (3 of 12 last);
			}
		}
		
		figure {
			margin: 0 0 .5rem 0;

			img {
				display: block;
			}

			figcaption {
				color: gray;
				font-size: 1.6rem;
			}
		}
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.gallery {
			@include span (10 of 12);
		}
	}

	@media (max-width: 1024px) {
		.gallery {
			@include span (full);
		}
	}

	@media (max-width: 500px) {
		.gallery .box {
			@include span (6 of 12);

			&:nth-of-type(2n) {
				@include span (6 of 12 last);
			}
		}
	}