/************************************************
* ICONS (fontello.com)
*************************************************/

@font-face {
  font-family: 'ege-icons';
  src: url('../fonts/ege-icons.eot?79984106');
  src: url('../fonts/ege-icons.eot?79984106#iefix') format('embedded-opentype'),
       url('../fonts/ege-icons.woff2?79984106') format('woff2'),
       url('../fonts/ege-icons.woff?79984106') format('woff'),
       url('../fonts/ege-icons.ttf?79984106') format('truetype'),
       url('../fonts/ege-icons.svg?79984106#ege-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icons{
    font-family: "ege-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %icons;
}

.icon-comment:before { content: '\e800'; } /* '' */
.icon-user:before { content: '\e801'; } /* '' */
.icon-users:before { content: '\e802'; } /* '' */
.icon-basket:before { content: '\e803'; } /* '' */
.icon-door:before { content: '\e804'; } /* '' */
.icon-shield:before { content: '\e805'; } /* '' */
.icon-quality-badge:before { content: '\e806'; } /* '' */
.icon-folder:before { content: '\e807'; } /* '' */
.icon-phone-call:before { content: '\e808'; } /* '' */
.icon-placeholder:before { content: '\e809'; } /* '' */
.icon-clock:before { content: '\e80a'; } /* '' */
.icon-speech-bubble:before { content: '\e80b'; } /* '' */
.icon-shopping-cart-of-checkered-design:before { content: '\e80c'; } /* '' */
.icon-magnifier:before { content: '\e80d'; } /* '' */
.icon-cloud-computing:before { content: '\e80e'; } /* '' */
.icon-right-open-big:before { content: '\e80f'; } /* '' */
.icon-right-thin:before { content: '\e810'; } /* '' */
.icon-user-1:before { content: '\e811'; } /* '' */
.icon-play-circled2:before { content: '\e812'; } /* '' */
.icon-play:before { content: '\e813'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-certificate:before { content: '\f0a3'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
.icon-level-down:before { content: '\f149'; } /* '' */
.icon-xing:before { content: '\f168'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-gplus:before { content: '\f30f'; } /* '' */