/************************************************
* General CONTENT Stylings
*************************************************/

.headerImage {

	@include container(1800px);
	margin-bottom: 3rem;
	position: relative;

	img{
		display: block;
	}

}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width:2000px) {

		.headerImage {
			max-width: 92%;
		}

	}

	@media (max-width:1400px) {

		.headerImage {
			max-width: 95%;
		}
	}

	@media (max-width:1100px) {

		.headerImage {
			max-width: 100%;
		}

	}