/************************************************
* Bild + Text 2-spaltig (kreisförmiges Bild) (Cluster Layout 10)
*************************************************/

.imageText2ColCircleImage {

	margin-bottom: 3rem;
	@include span(full);

	div {
		@include span(6 of 12);

		figure {
			margin: 0;
			@include span (1 of 6);
		}

		.content {
			@include span (5 of 6 last);
			padding: 0;
			*:first-child{
				margin-top: 0;
			}
		}

		ul {
			margin: 0;
			padding: 0;
		}

	}

	div:nth-of-type(2n) {
		@include span(6 of 12 last);
	}

}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width:750px) {
		.imageText2ColCircleImage {
			div {
				@include span(full);
			}
			div:nth-of-type(2n) {
				@include span(full last);
			}
		}
	}