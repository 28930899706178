/************************************************
* Bild + Text untereinander 2-spaltig (Fließtextbreite) (Cluster Layout 20)
*************************************************/

.imageText2ColImageBelow {

	@include span (7 of 12);
	margin-bottom: 3rem;

	.box {
		@include span(6 of 12);
		margin-bottom: 3rem;
		
		figure {
			@include span (full);
			margin: 0 0 1rem 0;
		}

		.title {
			font-size: 1.6rem;
			font-weight: bold;
		}

		&:nth-of-type(2n) {
			@include span(6 of 12 last);
		}

	}

}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.imageText2ColImageBelow {
			@include span (10 of 12);
		}
	}

	@media (max-width: 1024px) {
		.imageText2ColImageBelow {
			@include span (full);
		}
	}

	@media (max-width: 550px) {
		.imageText2ColImageBelow {
			.box {
				@include span(full);

				&:nth-of-type(2n) {
					@include span(full);
				}

			}
		}
	}