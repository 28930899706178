/************************************************
* boxCategories (News Listing)
*************************************************/

main .boxCategories {

	border: 1px solid $bg-grey;

	.title {
		background-color:#333;
		color: white;
		text-transform: uppercase;
		font-size: 1.5rem;
		padding: 1rem;
		text-align: center;
	}

	ul {

		padding: 0 2rem;
		margin: 0;

		li {
			border-bottom: 1px solid $bg-grey;
			padding: 0;

			&::before{
				content: none;
			}

			a {
				color: $first;
				display: block;
				padding: 2rem;
				position: relative;

				&::before {
					@extend %icons;
					content: '\e80f';
					color: $linkPrimary;
					transition: all ease .5s;
					position: absolute;
					left: 0;
				}

				&:hover {
					color:$linkPrimary;
				}
			}
			
			&:last-child {
				border-bottom:none;
			}
		}

	}

}