/************************************************
* Google Maps 3-spaltig (Cluster Layout 120)
*************************************************/

.boxesGoogleMaps {

	@include span(full);
	margin-bottom: 3rem;

	.boxes{
		@include span(full);
	}

	.box {
		@include span(4 of 12);
		background-color: $bg-grey;
		padding: 4rem;
		text-align: center;

		&:nth-of-type(3n) {
			@include span(4 of 12 last);
		}

		figure {
			margin: 0;
		}

		.embed-container {
		  position: relative; 
		  padding-bottom: 70%;
		  height: 0; 
		  overflow: hidden; 
		  width: 100%;
		  height: auto;
		  margin-top: 4rem;
		}

		.embed-container iframe {
		  position: absolute; 
		  top: 0; 
		  left: 0; 
		  width: 100%; 
		  height: 100%; 
		}

	}


}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 1000px) {

	.boxesGoogleMaps {
		.box {
			@include span(6 of 12);
			margin-bottom: gutter() * 2;

			&:nth-of-type(2n) {
				@include span(6 of 12 last);
			}

			&:nth-of-type(3n) {
				@include span(6 of 12);
			}

		}
	}

}

@media (max-width: 750px) {

	.boxesGoogleMaps {
		.box {
			@include span(full);

			&:nth-of-type(2n) {
				@include span(full);
			}

			&:nth-of-type(3n) {
				@include span(full);
			}

		}
	}

}