.f3-widget-paginator {
    margin: 1em 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: center;

    li:before {
        content: none;
        padding: 0;
    }

    li {
        margin: 0 2px;

        a:link,
        a:visited {
            background-color: $linkPrimary;
            border-radius: 5px;
            color: #fff;
            display: block;
            font-weight: 400;

            padding: .4em .6em;
            transition: background 250ms;

            &:hover {
                background-color: $hoverPrimary;
                color: #fff;
            }
        }
        &.current {
            border-radius: 5px;
            border: 1px solid #cecece;
            font-size: 1.6em;
            line-height: 2.2em;
            display: inline-block;
            text-align: center;
        }
        &.prev a,
        &.next a {
            min-width: 80px;
        }
    }
}


main ul.f3-widget-paginator li {
    padding-left: inherit;
    text-align: center;
    position: inherit;
    min-width: 44px;

    &:before {
        content: none;
    }
}