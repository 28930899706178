/************************************************
* .scheduler
*************************************************/

.scheduler {
	@include span(8 of 12);
	margin-bottom: 3rem;

	.title {
		font-size: 2rem;
		font-weight: 600;
		color: $first;
		margin-bottom: 2rem;
	}

	.message{
		@include span(full);
	}

	.calendar {
		@include span(3 of 8 last);

		.inner {
			border: 1px solid $first;
		}

		.selector {
			border-bottom: 1px solid lighten($first, 50%);
			text-align: center;
			padding: 2rem 1rem;
			color: $first;
			font-size: 1.6rem;
			position: relative;

			.prev,
			.next{
				position: absolute;
				display: block;
				top: 50%;
				padding: 2rem 1rem;
				transform: translateY(-50%);
			}

			.prev {
				left: 1rem;
				content: '\f104';
			}

			.next {
				right: 1rem;
				content: '\f105';
			}

		}

		.overview {

			padding: 1.2rem 1rem;

			.week {
				width: 100%;
				display: flex;
			}

			span {
				display: inline-block;
				padding: 1.5rem 1rem 1.2rem 1rem;
				text-align: center;
				width: 14.06%;
				margin: 1%;
				border-bottom: .2rem solid $linkPrimary;
			}

			.today {
				font-weight: 600;
			}

			.past {
				border-color: #DDDDDD;
			}

			.empty {
				border-color: white;
			}

			.reserved {
				color: #FF4136;
				border-color: #FF4136;
			}

		}

	}

	form {
		@include span(5 of 8);

		.formField {
			@include span(full);
			margin-bottom: 2rem;
			&.half {
				@include span(2 of 4);
				&.last {
					@include span(2 of 4 last);
				}
			}
		}

		label {
			@include span(full);
			color: $first;
			font-size: 1.8rem;
			margin-bottom: 1rem;
		}

		input,
		textarea {
			border: 1px solid black;
			padding: 1rem;
			font-size: 1.8rem;
			width: 100%;
			font-family: 'Source Sans Pro', sans-serif;
		}

		textarea {
			height: 100%;
		}

	}

	/* Submit */

	.actions {

		@include span(full);
		text-align: right;

		input[type="submit"] {
			border: 1px solid black;
			color: black;
			line-height: 1.5;
			background: transparent none;
			cursor: pointer;
			width: auto;

			font-size: 1.8rem;
			font-weight: 400;
			padding: 1.5rem 4rem;
			transition: .3s ease-in-out;

			@media (min-width: 1025px) {
				&:hover {
				  background-color: green;
				  color: white;
				}
			}

		}
	}

}