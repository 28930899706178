/************************************************
* Featureboxen 4-spaltig (kreisförmiges Bild) (Cluster Layout 60)
*************************************************/

.boxesFeature4ColCircleImage {
	@include span(full);
	margin-bottom: 3rem;

	.feature {
		@include span(3 of 12);
		background-color: $bg-grey;
		padding: 4rem 2rem;
		text-align: center;
		margin-bottom: 5rem;

		div {
			background-color: white;
			// border-radius: 50%;
			width: 12rem;
			height: 12rem;
			display: inline-block;
			margin: 5px;
			transition: all .3s ease;

			img {
				width: 12rem;
				height: 12rem;
				// border-radius: 50%;
			}
		}

		ul {
			margin: 0 0 2rem;
			padding: 0;
			li {
				margin-bottom: .5rem;
			}
		}

		p {
			margin-bottom: 2rem;
		} 
	}

	.feature:nth-of-type(4n) {
		@include span(3 of 12 last);
	}
}

/************************************************
* RESPONSIVE
*************************************************/


@media (max-width:1150px) {
	.boxesFeature4ColCircleImage {
		.feature {
			@include span(6 of 12);
			margin-bottom: 1.5rem;
		}
		.feature:nth-of-type(2n) {
			@include span(6 of 12 last);
		}
	}
}

@media (max-width:600px) {
	.boxesFeature4ColCircleImage {
		.feature {
			@include span(full);
			margin-bottom: 1.5rem;
		}
		.feature:nth-of-type(2n) {
			@include span(full last);
		}
	}
}
