/************************************************
* Configurator (Text / Media - Layout 220)
*************************************************/

	.configurator {
		@include span(1000px);
		margin-bottom: 3rem;
		
		.embed-container {
		  position: relative; 
		  padding-bottom: 70%;
		  height: 0; 
		  overflow: hidden; 
		  width: 100%;
		  height: auto;
		}

		.embed-container iframe {
		  position: absolute; 
		  top: 0; 
		  left: 0; 
		  width: 100%; 
		  height: 100%; 
		}

	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1100px) {
		.configurator {
			@include span (full);
		}	
	}