/************************************************
* Text Standard (Text / Media - Layout Default)
*************************************************/

.textStandard {
	@include span(7);
	margin-bottom: 3rem;

	ul {
		padding-left: 0;
	}

	li {
		text-align: left;
		line-height: 1.6;
		padding-left: 2rem;
		margin-bottom: 1.5rem;
		position: relative;

		&::before {
			@extend %icons;
			content: '\e80f';
			color:$linkPrimary;
			position: absolute;
			left: 0; 
			top: .3rem;
			font-size: 1.2rem;
		}
	}

	*:last-child{
		margin-bottom: 0;
	}	
}


/************************************************
* RESPONSIVE
*************************************************/

@media (max-width:1400px) {

	.textStandard {
		@include span(9);	
	}

}

@media (max-width:1100px) {

	.textStandard {
		@include span(full);	
	}

}