/************************************************
* .newsList
*************************************************/

.newsDetail {
	@include span(6 of 9);
	margin-bottom: 3rem;

	a[href^='https://www.youtube.'],
	a[href^='https://youtube.']{
		position: relative;
		display: block;
		&::after{
			@extend %icons;
			content: '\f144';
			font-size: 8rem;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.text{
		margin-bottom: 3rem;
	}

	.images{
		margin-bottom: 3rem;
		@include span(full);
		a{
			@include span(2 of 6);
			display: block;
			margin-bottom: 3rem;
			&:nth-of-type(3n){
				@include span(2 of 6 last);
			}
		}
		img{
			display: block;
		}
	}

	.downloads{
		margin-bottom: 3rem;
	}

	a.button {
		margin: 1rem 0;
	}

}

/************************************************
* Responsive
*************************************************/

@media (max-width:1400px) {

	.newsDetail {
		@include span(9 of 9);

		.images{
			a{
				@include span(3 of 9);
				&:nth-of-type(3n){
					@include span(3 of 9 last);
				}
			}
		}

	}

}

@media (max-width:600px) {

	.newsDetail {
		@include span(full);

		.images{
			a{
				@include span(4 of 12);
				&:nth-of-type(3n){
					@include span(4 of 12 last);
				}
			}
		}

	}

}

@media (max-width:450px) {

	.newsDetail {
		.images{
			a{
				@include span(6 of 12);
				&:nth-of-type(3n){
					@include span(6 of 12);
				}
				&:nth-of-type(2n){
					@include span(6 of 12 last);
				}
			}
		}

	}

}