/*****************
* Colors
******************/

	$textPrimary: #808080;
	$linkPrimary:#005A97;
	$first: #555;
	$fontColor:#565656;
	$bg-grey:#e8e8e8;
	$light-grey:#cccccc;
	$hoverPrimary:#139DE0;
	$footerPrimary:#cecece;
