/************************************************
* Frontend Login Form (Händlerbereich)
*************************************************/

.tx-felogin-pi1{

	& > div {
		font-size: 1.6rem;
		font-weight: 400;
		color: $textPrimary;
		line-height: 1.5;
		margin-bottom: 2rem;
	}

	form {

		div{
			margin-bottom: 2rem;
		}

		label {
			@include span(full);
			color: $first;
			font-size: 1.8rem;
			margin-bottom: 1rem;
		}

		input {
			border: 1px solid black;
			padding: 1rem;
			font-size: 1.8rem;
			width: 100%;
			font-family: 'Source Sans Pro', sans-serif;
		}

		fieldset {
			border: 0;
			padding: 0;
		}

		legend {
			display: none;
		}

		input[type="submit"]{
			line-height: 1.5;
			background: transparent none;
			width: auto;
			display: inline-block;
			margin-bottom: .2rem;
			margin-right: 1rem;
			transition: .3s ease-in-out;
			cursor: pointer;

			@media (min-width: 1025px) {
				&:hover {
				  background-color: $linkPrimary;
				  color: white;
				}
			}
		}

	}

}