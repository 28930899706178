/************************************************
* Featureboxen 3-spaltig grau (quadratisches Icon) (Cluster Layout 50)
*************************************************/

.boxesFeature3ColCircleImage {
	@include span(full);
	margin-bottom: 3rem;

	.boxes{
		@include span(full);
	}

	.feature {
		@include span(4 of 12);
		display: block;
		padding: 4rem 3rem;
		text-align: center;
		background-color: $bg-grey;
		color: $textPrimary;
		transition: color ease-in-out .5s, background-color ease-in-out .5s;
		margin-bottom: 5rem;

		&:nth-of-type(3n) {
			@include span(4 of 12 last);
		}

		div {
			width: 9rem;
			height: 9rem;
			display: inline-block;
			margin: 5px;
			transition: all .3s ease;

			img {
				display: block;
			}

		}

		h2, h3 {
			transition: color ease-in-out .5s;
		}

		p {
			margin-bottom: 2rem;
			transition: color ease-in-out .5s;
		}

		&:hover {
			background-color:$linkPrimary;

			button.more-btn {
				background-color: white;
				color: $linkPrimary;
			}

			h2,
			h3,
			p {
				color: white;
			}
		}
	}
}


/************************************************
* RESPONSIVE
*************************************************/

@media (max-width:1100px) {
	.boxesFeature3ColCircleImage {

		.feature {
			@include span(6 of 12);
			margin-bottom: 2rem;
		}

		.feature:nth-of-type(2n) {
			@include span(6 of 12 last);
		}

		.feature:nth-of-type(3n) {
			@include span(6 of 12);
		}
	}
}

@media (max-width:1024px){
	.boxesFeature3ColCircleImage {
		.feature {
			
			&:hover {

				color:$textPrimary;
				background-color:$bg-grey;

				&:hover button.more-btn {
					background-color: $linkPrimary;
					color: white;
				}

				h2, h3 {
					color: $first;
				}

				p {
					color: $textPrimary;
				}
				
			}
			
		}
	}
}

@media (max-width:600px) {
	.boxesFeature3ColCircleImage {
		.feature {
			@include span(full);
		}

		.feature:nth-of-type(2n),
		.feature:nth-of-type(3n) {
			@include span(full);
		}
	}
}