/************************************************
* Bild + Text untereinander 4-spaltig (Cluster Layout 20)
*************************************************/

.imageText4ColImageBelow {

	@include span (full);
	margin-bottom: 3rem;

	.box {
		@include span(3 of 12);
		margin-bottom: 3rem;

		figure {
			@include span (full);
			margin: 0 0 1rem 0;
		}

		.title {
			font-size: 1.6rem;
			font-weight: bold;
		}

		&:nth-of-type(4n) {
			@include span(3 of 12 last);
		}

		ul {
			padding-left: 0;
		}

	}

}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 1150px) {

	.imageText4ColImageBelow {

		.box {
			@include span(6 of 12);

			&:nth-of-type(2n),
			&:nth-of-type(4n) {
				@include span(6 of 12 last);
			}

		}

	}

}

@media (max-width: 550px) {

	.imageText4ColImageBelow {

		.box {
			@include span(full);

			&:nth-of-type(2n),
			&:nth-of-type(4n) {
				@include span(full);
			}

		}

	}

}