/************************************************
* Bild + Text nebeneinander (Text / Media - Layout 100)
*************************************************/
	
	.imageText {
		@include span (7 of 12);
		margin-bottom: 3rem;

		figure {
			margin: 0;
			@include span (5 of 12);
		}

		.text {
			@include span (7 of 12 last);

			h2,
			h3 {
				margin-top: 0;
			}

			p:first-of-type {
				margin-top: 0;
			}

			ul {
				padding-left: 0;
			}
		}
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.imageText {
			@include span (10 of 12);
		}
	}

	@media (max-width: 1024px) {
		.imageText {
			@include span (full);
		}
	}

	@media (max-width: 600px) {
		.imageText {
			figure {
				@include span (full);
				margin-bottom: 2rem;
			}
		
			.text {
				@include span (full);
			}
		}
	}