/************************************************
* Textbox grau (Text / Media - Layout 140)
*************************************************/

	.highlightBox {
		@include span (7 of 12);
		margin-bottom: 3rem;
		background-color: $bg-grey;
		padding: 4rem;

		h1,
		h2,
		h3 {
			margin-top: 0;
		}
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.highlightBox {
			@include span (10 of 12);
		}
	}

	@media (max-width: 1024px) {
		.highlightBox {
			@include span (full);
		}
	}

	@media (max-width: 600px) {
		.highlightBox {
			margin: 3rem 0;
			padding: 2.5rem;
		}
	}