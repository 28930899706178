/************************************************
* Überschrift zentriert (Text / Media - Layout 160)
*************************************************/

	.headlineCentered {
		@include span (full);
		text-align: center;
		
		h1::after{
			content: none;
		}
	}

/************************************************
* RESPONSIVE
*************************************************/