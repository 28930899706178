/************************************************
* Buttons
*************************************************/

	button,
	a.button {
		display: inline-block;
		padding: 1rem 2rem 1rem;
		background-color: $linkPrimary;
		color: white;
		transition: all ease .5s;
		font-size: 1.5rem;
		border: 0;
		text-transform: uppercase;
		cursor: pointer;

		@media (min-width: 1025px) {

			&:hover {
				background-color: lighten($linkPrimary, 10%);
			}

		}

		&.white {
			background-color: white;
			color: $linkPrimary;
		}

		&.more-btn {

			&::after {
				@extend %icons;
				content: '\e810';
				margin-left:1rem;
			}

		}

	}