/************************************************
* natural box-sizing
*************************************************/

	*, *:after, *:before{
		box-sizing: border-box;
	}

/************************************************
* responsive images
*************************************************/

	img {
		max-width: 100%;
		height: auto;
	}

/************************************************
* general CSS Stylings
*************************************************/

	body {
		margin: 0;
	}

	header,
	main {
		@include container(1600px);
		display: block;
		
	}

	main header {
		clear: both;
		overflow: auto;
		margin-bottom: 3rem;
		h2 {
			text-align: center;
		}
	}
	

	.leftCol{
		@include span(9 of 12);
	}

	.rightCol{
		@include span(3 of 12 last);
	}

	main ul:not(.select2-selection__rendered) {
	  list-style-type: none;
	  li {

	    text-align: left;
	    line-height: 1.5;
	    padding-left: 2rem;
	    position: relative;

	    &:before {
	      position: absolute;
	      left: .5rem;
	      top: .6rem;
	      @extend %icons;
	      content: '\e80f';
	      color: $linkPrimary;
	      transition: all ease .5s;
	      font-size: 1rem;
	    }
	  }
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width:2000px) {

		header,
		main {
			max-width: 84%;
		}

	}

	@media (max-width:1650px) {

		header,
		main {
			max-width: 90%;
		}

	}

	@media (max-width:1400px) {

		header {
			max-width: 95%;
		}

		main {
			max-width: 93%;
		}

	}

	@media (max-width:1100px) {

		header {
			max-width: 100%;
		}

		main {
			max-width: 95%;
		}

		.leftCol{
			@include span(8 of 12);
		}

		.rightCol{
			@include span(4 of 12 last);
		}

	}

	@media (max-width:600px) {

		.leftCol{
			@include span(full);
			margin-bottom: 3rem;
		}

		.rightCol{
			@include span(full);
			margin-bottom: 3rem;
		}

	}