/************************************************
* Referenztext 2-spaltig (Cluster Layout 110)
*************************************************/

.referenceText2Col {

	@include span(9 of 12);
	margin-bottom: 3rem;

	.boxes{
		@include span(full);
	}

	.box {
		@include span (6 of 9);

		&:nth-of-type(2n) {
			@include span(3 of 9 last);
		}
	}

}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 1350px) {

	.referenceText2Col {

		@include span(10 of 12);

		.box {
			@include span (7 of 10);

			&:nth-of-type(2n) {
				@include span(3 of 10 last);
			}
		}

	}

}

@media (max-width: 1024px) {

	.referenceText2Col {

		@include span(full);

		.box {
			@include span (8 of 12);

			&:nth-of-type(2n) {
				@include span(4 of 12 last);
			}
		}

	}

}

@media (max-width: 650px) {

	.referenceText2Col {

		.box {
			@include span (full);

			&:nth-of-type(2n) {
				@include span(full);
			}
		}

	}

}