/************************************************
* Featureboxen 4-spaltig (Referenzen) (Cluster Layout 70)
*************************************************/

.filterSelector {


	a {
		color: darken($light-grey, 20%);
		border: 1px solid $light-grey;
		display: inline-block;
		margin: 1.3em .2em;
		padding: .3em .6em;
		font-size: 1.25em;
		font-weight: 400;

		&:hover {
			color: darken($light-grey, 40%);
		}
		&.active {
			color: #fff;
			border-color: $linkPrimary;
			background-color: $linkPrimary;
		}
	}	
}

main .filterSelector a[href^="#"]:after {
    content: none;
}

.boxesFeature4ColReferences {
	@include span(full);
	margin-bottom: 3rem;

	.boxes {
		@include span(full);
	}

	.reference {
		@include span(3 of 12);
		background-color: $bg-grey;
		position: relative;
		margin-bottom: gutter();
		display: block;

		&:nth-of-type(4n) {
			@include last;
		}

		.background {
			background-size: cover;
			background-position: center;
			padding-top: 100%;
			transition: opacity 0.32s ease;
		}

		p {
			position: absolute;
			visibility: hidden;
			bottom: 5rem;
			z-index: 1;
			width: 100%;
			display: block;
			padding: 0 2rem;
			margin: 0;
			opacity: 0;
			transition: all 0.32s ease;
		}

		img {
			opacity: 1;
			transition: all 0.32s ease;
			width: 100%;
			height: auto;
			display: block;
		}


		&:hover {

			.background {
				opacity: 0;
			}

			p {
				opacity: 1;
				visibility: visible;
				z-index: 13;
				bottom: 8rem;
			}

			.boxText {
				background-color: $bg-grey;
			}

			span {
				color: $first;
			}

			img {
				opacity: 0;
			}

		}

	}

	.boxText {
		padding: 1rem 8rem 1rem 2rem;
		position: absolute;
		bottom: -1px;
		width: 100%;
		background-color: $linkPrimary;
		transition: background-color 0.5s ease;

		span {
			color: white;
			text-transform: uppercase;
			margin: 0;
			transition: color 0.5s ease;
		}
	}

	.arrow {
		position: absolute;
		background-color: #0da871;
		top: 0;
		right: 0;
		bottom: 0;
		width: 6rem;

		&::after {
			@extend %icons;
			content: '\e810';
			font-size: 1.8rem;
			position: absolute;
			color: white;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

/************************************************
* RESPONSIVE
*************************************************/

@media (min-width:1024px) {
	.filterSelector {
		float: right;
		position: relative;
		margin-top: 2.5em;
		z-index: 10;		
	}
}


@media (max-width:1024px) {

	.boxesFeature4ColReferences {
		
		.reference {
			@include span(6 of 12);

			.background {
				opacity: .1;
			}

			p {
				visibility: visible;
				opacity: 1;
				display: block;
				padding: 2rem;
			}

			&:nth-of-type(2n) {
				@include last;
			}

			&:hover h4 {
				color: white;
			}
			
			&:hover span {
				color: white;
			}

			&:hover img {
				opacity: .1;
			}
			
			&:hover p {
				bottom: 5rem;
			}

			&:hover .boxText {
				background-color: $linkPrimary;
			}

			&:hover .background {
				opacity: .1;
			}
			
		}
	}
}

@media (max-width: 600px) {

	.boxesFeature4ColReferences {

		.reference {
			@include span(full);
			margin-bottom: gutter() * 2;

			&:last-child {
				@include span(full);
			}

			&:nth-of-type(n) {
				@include span(full);
			}
		}

	}

}