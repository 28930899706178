/* clearfix */

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


/* Breakpoints */

@mixin bp($point) {
  
    $bp-phonep: "(max-width: 320px)";
    $bp-phonel: "(max-width: 480px)";
    $bp-tabletp: "(max-width: 768px)";
    $bp-tabletl: "(max-width: 1023px)";
    $bp-desktop: "(max-width: 1200px)";

    @if (type-of($point) == string) {

        @if $point == desktop {
            @media #{$bp-desktop} { @content; }
        }@else if $point == tabletl {
            @media #{$bp-tabletl} { @content; }
        }@else if $point == tabletp {
            @media #{$bp-tabletp}  { @content; }
        }@else if $point == phonel {
            @media #{$bp-phonel} { @content; }
        }@else if $point == phonep {
            @media #{$bp-phonep}  { @content; }
        }

    } @else if (unit($point) == px) {
        @media (max-width: $point)  { @content; }
    }

}

// Baseline, measured in pixels
// The value should be the same as the font-size value for the html element
// If the html element's font-size is set to 62.5% (of the browser's default font-size of 16px),
// then the variable below would be 10px.
$baseline-px: 10px;
@mixin rem($property, $px-values) {
  
  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;
  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    
    #{$property}: $px-values / $baseline-rem;
    
  } @else {
    
    // Create an empty list that we can dump values into
    $rem-values: ();
    
    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value == 0 or type-of( $value ) != "number" {
        $rem-values: append($rem-values, $value);
      } @else {
        $rem-values: append($rem-values, $value / $baseline-rem);
      }
    }
    
    // Return the property and its list of converted values
    #{$property}: $rem-values;
    
  }
  
}