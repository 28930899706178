/************************************************
* Kontaktinfos 3-spaltig mit Icon (Cluster Layout 90)
*************************************************/

.contactInfo3Col{
	
	@include span(full);
	margin-bottom: 3rem;

	.boxes{
		@include span(full);
	}

	> h1, > h2, > h3 {
		text-align: center;
		margin: 0 0 3rem 0;

		&::before,
		&::after {
			content: none;
		}
	}

	.box {
		@include span(4 of 12);
		max-width: 31.5%;
		position: relative;

		&:nth-child(3) {
			// @include span(4 of 12 last);
		}

		h1, h2, h3 {
			color: $textPrimary;
			font-size: 1.5rem;
		}

		figure {

			margin: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 6rem;
			width: 6rem;
			left: 1rem;

			img{
				position: absolute;
				display: block;
			}

		}

		.content {
			padding-left: 10rem;
		}

	}

}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width:1000px) {
	.contactInfo3Col {


		.box {
			@include span(6 of 12);

			&:nth-child(3){
				@include span(6 of 12);				
			}

			&:nth-child(2) {
				@include span(6 of 12 last);
			}
		}
	}
}

@media (max-width:600px) {
	.contactInfo3Col {
		margin:5rem 0;

		.box {
			@include span(full);
			
			&:nth-child(2),
			&:nth-child(3) {
				@include span(full);
			}
		}

	}
}
