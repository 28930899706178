/************************************************
* Formulare
*************************************************/

.formElement {
	@include span(8 of 12);
	margin-bottom: 3rem;

	h1 {
		text-align: center;
		margin: 0 0 5rem 0;
		&::before,
		&::after {
			content: none;
		}

	}

	h2 {
		margin: 0;
	}

	p {
		margin: 0;
	}

	.form-group {
		@include span(full);
	}

	/*********************************************
	 * Grid Row
	 */ 

	.form-group,
	.row {

		@include span(full)
		margin-bottom: 2rem;
		
		/* 2 Elemente in einer Grid Row */
		& > .col-xs-6.col-sm-6.col-md-6.col-lg-6 {
			@include span(4 of 8);
			&:last-child{
				@include span(4 of 8 last);
			}
		}

		+ .row h2{
			margin-top: 3rem;
		}

	}

	/* Label */

	label {
		@include span(full);
		color: $first;
		font-size: 1.6rem;
		font-weight: bold;
		margin-bottom: .3rem;
	}

	/* Fieldset */

	fieldset {
		margin: 0 0 2rem 0;
		padding: 0;
		border: 0;

		legend{
			font-size: 2rem;
			font-weight: 600;
			color: lighten($first, 20%);
			margin-bottom: 0;
			padding: 1em 0 0 0;
		}

		> .form-group,
		.clearfix {
			margin-top: 2rem;
			margin-bottom: 0;
		}

	}

	/* Input & Textarea */

	.input {

		@include span(full);

		input,
		textarea {
			border: 1px solid #ccc;
			padding: 1rem;
			font-size: 1.8rem;
			width: 100%;
			font-family: 'Source Sans Pro', sans-serif;
		}

		textarea {
			height: 100%;
		}

		
	}

	input[type="checkbox"] {
		width: 1em;
		float: left;
		margin-top: .25em;
		margin-right: .2em;			
	}

	/* file input */

	.fileWrapper {
		position: relative;

		label {
			display: block;
			height: 100%;
			font-size: 1.8rem;
			padding: 1rem 1rem 1rem 4rem;
			width: 100%;
			position: absolute;
			font-weight: normal;
			line-height: 1.8em;
			font-size: 1.65em;

			&:before{
				@extend %icons;
				content: '\e807';
				position: absolute;
				left: 1rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&.selected{
				&:before{
					content: '\f0f6';
				}
			}
		}

		.input {
			border: 1px solid #ccc;
		}

		input {
			opacity: 0;
			visibility: hidden;
		}

		& > span{
			width: 100%;
			display: block;
		}
	}

	/* Radio Buttons */

	.inputs-list {

		.form-check {
			@include span(2 of 8);
			&:nth-of-type(4n){
				@include span(2 of 8 last);
			}
		}

		.form-group {
			padding: 0;
		}

		label.form-check-label {

			input[type="radio"]{
				width: auto;
				display: inline-block;
				margin-bottom: .2rem;
				margin-right: 1rem;
			}

		}

	}

	/* Checkboxen */

	.input.checkbox {

		> div {
			@include span(full);
		}

		.form-check{
			@include span(4 of 8);
			&:nth-of-type(2n){
				@include span(4 of 8 last);
			}
		}

		label {
			vertical-align: bottom;
		}

		span {
			font-size: 1.8rem;
		}

		input[type="checkbox"]{
			width: auto;
			display: inline-block;
			margin-bottom: .2rem;
			margin-right: 1rem;
		}

	}

	/* Submit */
	.actions {

		@include span(full);
		text-align: right;

		button {
			border: 1px solid #999;
			color: black;
			line-height: 1.5;
			background: transparent none;
			cursor: pointer;
			line-height: 1em;

			font-size: 1.8rem;
			font-weight: 400;
			padding: 1.2rem 3rem;
			transition: .3s ease-in-out;

			@media (min-width: 1025px) {
				&:hover {
				  background-color: $linkPrimary;
				  color: white;
				}
			}

		}
	}

}

/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/

	.select2-container--default{

	 	font-size: 1.6rem;
	 	width: 100%;

	 	.select2-selection--single{

	 		border: 1px solid #ccc;
	 		font-size: 1.8rem;
	 		border-radius: 0;
	 		padding: .7rem .7rem .8rem .7rem;
	 		padding-right: 4rem;
	 		height: auto;

	 		.select2-selection__arrow {
	 			height: auto;
	 			bottom: 0;
	 			width: 4rem;
	 			top: 0;
	 			right: 0;
	 			border: 1px solid #ccc;
	 			border-left: 0;

	 			b {
	 				border: none;
	 				&::after{
	 					@extend %icons;
	 					content: '\f107';
	 					position: absolute;
	 					top: -.5rem;
	 					left: -.8rem;
	 					font-size: 1.6rem;
	 					color: $fontColor;
	 				}
	 			}

	 		}

	 		.select2-selection__rendered {
	 			color: $fontColor;
	 			font-size: 1.8rem;
	 			padding: 0;
	 			li:before{
	 				content: '';
	 			}
	 		}

	 	}

	 	// Container im offenen Zustand
	 	&.select2-container--open{

	 		.select2-selection--single .select2-selection__arrow b {
	 			border: none;
	 			&::after{
	 				content: '\f106';
	 			}
	 		}

	 	}

	 	.select2-results__option {
	 		padding: 1rem;
	 		color: $fontColor;
	 	}

	 	/* Hintergrund für aktive Auswahl*/
	 	.select2-results__option[aria-selected="true"]{
	 		background-color: $bg-grey;
	 	}

	 	/* Hintergrund für gehoverte Auswahl*/
	 	.select2-results__option--highlighted[aria-selected]{
	 		background-color: $linkPrimary;
	 		color: white;
	 	}

	 	.select2-selection--multiple{
	 		border: 1px solid #ccc;
	 		font-size: 1.8rem;
	 		border-radius: 0;
	 		padding: .8rem .7rem .7rem .7rem;
	 		padding-right: 4rem;
	 		height: auto;
	 		line-height: 1.8rem;

	 		li {
	 			line-height: 1.5;
	 			font-size: 1.8rem;
	 		}

			.select2-selection__choice {
				margin-top: .2rem;
			}

	 		.select2-search--inline::before{
	 			content: '';
	 			input {
	 				font-size: 1.8rem;
	 			}
	 		}

	 	}

	}

	/* Hintergrund generell für die Optionen */
	.select2-dropdown{
		background-color: white;
		border-color: #ccc;
		border-width: 1px;
		border-radius: 0;
	}


/************************************************
 * RESPONSIVE
 ************************************************/	

  @media (max-width: 1024px) {
  	.formElement {
			@include span(full);
		}	
  }

  @media (max-width: 650px) {
  	.formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6,
  	.formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6:last-child {
  		@include span(full);
  	}

  	.formElement .row > .col-xs-6.col-sm-6.col-md-6.col-lg-6:last-child {
  		margin-top: 2rem;
  	}

  	.formElement .input.checkbox .form-check,
  	.formElement .input.checkbox .form-check:nth-of-type(2n) {
  		@include span(full);
  	}
  }
