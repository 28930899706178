/************************************************
* Featureboxen 3-spaltig (quadratisches Bild) (Cluster Layout 40)
*************************************************/

	.boxesFeature3ColSquareImage {	
		@include span(full);
		margin-bottom: 3rem;

		.featureHeadline {
			text-align: center;
			position: relative;
		
			h2, h3 {
				display: inline-block;
				padding: 10px;
				color:$textPrimary;	
				background-color:white;

				&:before {
					content: "";
					width: 100%;
					border-top: 1px solid $footerPrimary;
					height: 1px;
					left: 0;
					top: 5.1rem;
					display: block;
					position: absolute;
					background: #fff;
					z-index: -1;
				}
			}
		}

		.boxes {
			@include span (full);

			// generelle Stylings

			.feature {
				@include span (4 of 12);
				display: block;
				margin-bottom: 5rem;

				&:nth-child(3n) {
					@include span (4 of 12 last);
				}

				img {
					margin-bottom: 1rem;
					transition: all .3s ease-in-out;
				}

				span {
					font-size: 1.8rem;
					font-weight: 600;
					color: gray;
					transition: all .3s ease-in-out;
				}

				span + p {
					margin-top: .5rem;
				}
			}

			// Stylings wenn verlinkt

			a.feature:hover {
				img {
					opacity: .7;
				}

				span {
					color: $linkPrimary;
				}
			}	

		}
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1024px) {
		.boxesFeature3ColSquareImage {
			.boxes a.feature:hover {
				img {
					opacity: 1;
				}

				span {
					color: gray;
				}
			}
		}
	}

	@media (max-width:900px) {
		.boxesFeature3ColSquareImage {
			.boxes .feature,
			.boxes .feature:nth-child(3n) {
				@include span (6 of 12);
			}

			.boxes .feature:nth-child(2n) {
				@include span (6 of 12 last);
			}
		}
	}

	@media (max-width: 600px) {
		.boxesFeature3ColSquareImage {
			.boxes .feature,
			.boxes .feature:nth-child(2n),
			.boxes .feature:nth-child(3n) {
				@include span (full);
				margin-bottom: 1.5rem;
			}
		}
	}