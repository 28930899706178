/************************************************
* Bild (Referenz) (Text / Media - Layout 70)
*************************************************/

.referenceGallery {
	@include span(9 of 12);
	text-align: center;
	margin-bottom: 3rem;

	img {
		display: block;
	}

	.referenceThumbnailWrapper{

		margin-top: 1rem;

		.swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.6;
      &.swiper-slide-active {
      	opacity: 1;
      }
    }

	}

	.swiper-button-next, .swiper-button-prev {
		background-color: $textPrimary;
		opacity: 0.5;
		margin-top: auto;
		width: 50px;
		height: 60px;
		transform: translateY(-50%);
	}

	.swiper-button-disabled {
		display: none;
	}

}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.referenceGallery {
			@include span (10 of 12);
		}
	}

	@media (max-width: 1024px) {
		.referenceGallery {
			@include span (full);
		}
	}