/************************************************
* SETUP
*************************************************/

	@import "_base/_setup/_mixins.scss";
	@import "_base/_setup/_settings.scss";
	@import "_base/_setup/_susy.scss";

/************************************************
* Base Definitions
*************************************************/

	@import "_base/_colors.scss";
	@import "_base/_typo.scss";
	@import "_base/_icons.scss";
	@import "_base/_default.scss";

/************************************************
* Library's (optional)
*************************************************/

	@import "_libs/_owl.carousel.scss";
	@import "_libs/_owl.theme.scss";
	@import "_libs/_swiper.scss";
	@import "_libs/_flexnav.scss";
	@import "_libs/_select2.scss";
	@import "_libs/_jquery.fancybox.scss";
	@import "_libs/_datepicker.scss";

/************************************************
* HEADER
*************************************************/

	@import "_header/_general.scss";
	@import "_header/_navMain.scss";
	@import "_header/_langSwitch.scss";
	@import "_header/_headerImage.scss";
	@import "_header/_breadcrumb.scss";
	@import "_header/_slider.scss";
	
/************************************************
* CONTENT
*************************************************/

	@import "_content/_cluster/_boxesFeature2Col.scss";
	@import "_content/_cluster/_boxesFeature3ColCircleImage.scss";
	@import "_content/_cluster/_boxesFeature3ColSquareImage.scss";
	@import "_content/_cluster/_boxesFeature4ColCircleImage.scss";
	@import "_content/_cluster/_boxesFeature4ColReferences.scss";
	@import "_content/_cluster/_boxesGoogleMaps.scss";
	@import "_content/_cluster/_boxesTextCol.scss";
	@import "_content/_cluster/_contactInfo3Col.scss";
	@import "_content/_cluster/_contactInfo4Col.scss";
	@import "_content/_cluster/_iconList2Col.scss";
	@import "_content/_cluster/_imageText2ColCircleImage.scss";
	@import "_content/_cluster/_imageText2ColImageBelow.scss";
	@import "_content/_cluster/_imageText4ColImageBelow.scss";
	@import "_content/_cluster/_referenceText2Col.scss";

	@import "_content/_text-media/_gallery.scss";
	@import "_content/_text-media/_boxesDownload.scss";
	@import "_content/_text-media/_configurator.scss";
	@import "_content/_text-media/_headlineCentered.scss";
	@import "_content/_text-media/_headlineLeft.scss";
	@import "_content/_text-media/_highlightBox.scss";
	@import "_content/_text-media/_imageFull.scss";
	@import "_content/_text-media/_imageText.scss";
	@import "_content/_text-media/_imageTextFull.scss";
	@import "_content/_text-media/_imageTextWidth.scss";
	@import "_content/_text-media/_logoList.scss";
	@import "_content/_text-media/_textStandard.scss";
	@import "_content/_text-media/_referenceGallery.scss";
	@import "_content/_text-media/_video.scss";

    @import "_content/_legend.scss";
    @import "_content/_pagination.scss";
	@import "_content/_boxCategories.scss";
	@import "_content/_formElement.scss";
	@import "_content/_buttons.scss";
	@import "_content/_newsList.scss";
	@import "_content/_newsDetail.scss";
	@import "_content/_scheduler.scss";
	@import "_content/_tx-felogin-pi1.scss";
	@import "_content/_fancybox.scss";

/************************************************
* FOOTER
*************************************************/

	@import "_footer/_general.scss";


#privacyInner {
    padding: 1em 0;
    margin: 0 auto;
    
    p {
        max-width: 95%;
    	color: #fff;
    	line-height: 1.3em;
    	text-align: center;

    	a {
    		color: #fff;
    	}
    }

    #closePrivacy {
        background-color: white;
        color: $first;
        display: inline-block;
        padding: 0 .5em;
        cursor: pointer;
        text-decoration: none;
        margin-left: 1em;
    }
}


	
