/************************************************
* Slider (Text / Media - Layout 10)
*************************************************/

	#slider {
		@include container(1800px);
		margin-bottom: 3rem;

		.slide {
			position: relative;
			@include span(full);

			.background {
				background-position: center;
				background-size: cover;
				padding-top: 33.3%;
			}

			.slider-details {
				position: absolute;
				right: 6rem;
				bottom: 6rem;
				padding: 2rem;

				max-width: 80rem;
				background-color: rgba(255,255,255,0.8);
				@include clearfix;

				.title {
					color:$first;
					font-weight: 600;
					text-align: left;
					font-size: 2.9rem;
					display: block;
					margin-bottom: 1rem;
				}

				.text {
					color: $first;
					font-weight: 400;
					font-size: 1.9rem;
					display: block;
					margin-bottom: 1rem;
				}

				a {
					float: right;
					margin-top:1rem;
				}
			}
		}
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width:2000px) {

		#slider {
			max-width: 92%;
		}

	}
	@media (max-width:1500px) {

		#slider {

			.slide {
				.slider-details {
					max-width:60rem;

					.title {
						font-size:2.5rem;
					}
					.text {
						font-size:1.6rem;
					}
				}
			}
		}
	}

	@media (max-width:1400px) {

		#slider {
			max-width: 95%;
		}
	}
	
	@media (max-width:1100px) {
		#slider {
			max-width: 100%;
			.slide {

				.background {
					padding-top:40%;
				}

				.slider-details {
					.title {
						font-size: 2.2rem;
					}
				}
			}
		}
	}

	@media (max-width:1024px) {

		#slider .slide {
			.slider-details {
				right: 3rem;
				bottom: 3rem;
				padding-left: 3rem;
				max-width: 60rem;

				.title {
					margin-bottom: 2rem;
				}
			}
		}

	}


	@media (max-width: 768px) {
		#slider .slide {

			.background {
				padding-top:50%;
			}

			.slider-details {
				max-width: 50rem;
			}
		}
	}

	@media (max-width: 700px) {
		#slider .slide .slider-details {
			position: relative;
			display: block;
			max-width: 100%;
			bottom: auto;
			right: auto;
			background-color: #f7f7f7;

		}
	}

	@media (max-width:400px) {
		#slider .slide .slider-details {
			padding:2rem;
			.title {
				font-size: 2rem;
			}

		}
	}
