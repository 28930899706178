/************************************************
* Text 2-spaltig (Cluster Layout 80)
*************************************************/

.boxesText2Col {

	@include span(full);
	margin-bottom: 3rem;

	.boxes{
		@include span(full);
	}

	.box {
		@include span(6 of 12);

		&:nth-of-type(2n) {
			@include span(6 of 12 last);
		}

		ul {
			padding-left: 0;
		}
	}
}

.boxesText3Col {

	@include span(full);
	margin-bottom: 3rem;

	.boxes{
		@include span(full);
	}

	.box {
		@media screen and (min-width: 850px) {
			@include span(4 of 12);
			&:nth-of-type(2n) {
				@include span(4 of 12 last);
			}
		}

		ul {
			padding-left: 0;
		}
	}
}


/************************************************
* RESPONSIVE
*************************************************/

@media (max-width:750px) {

	.boxesText2Col {
		.box {
			@include span(full);

			&:nth-of-type(2n) {
				@include span(full);
			}

		}
	}

}