/************************************************
* Featureboxen 2-spaltig (ohne Bild) (Cluster Layout 30)
*************************************************/

.boxesFeature2Col {

	@include span (full);
	margin-bottom: 3rem;

	> h1, > h2 {
		color: $first;
		text-align: center;
		margin-bottom: 3rem;

		&::before,
		&::after {
			content: none;
		}

	}

	.boxes{
		@include span(full);
	}

	.feature {
		@include span(6 of 12);
		background-color: $bg-grey;
		padding:5rem 3rem;
		margin-bottom:5rem;

		h2, h3 {
			color:$first;
			margin-top: 0;
		}

		p {
			color:$textPrimary;
		}

		span {
			color:$linkPrimary;
		}

		a {
			float: right;
			margin-top:1rem;
		}

		&:nth-of-type(2n) {
			@include span(6 of 12 last);
		}

		ul {
			padding-left: 0; 
		}

	}

}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 850px) {
	.boxesFeature2Col .feature {
		@include span(full);

		&:nth-of-type(2n) {
			@include span(full);
		}

	}
}

@media (max-width: 600px) {

	.boxesFeature2Col .feature {
		padding: 2.5rem;
	}
	
}
