/************************************************
* Video (Text / Media - Layout 220)
*************************************************/

	.video figure {
    	@include span (5 of 12);
		margin-bottom: 3rem;
	    position: relative; 
	    padding-bottom: 22.9%;
	    height: 0; 
	    overflow: hidden; 
	    height: auto;

		iframe {
		  position: absolute; 
		  top: 0; 
		  left: 0; 
		  width: 100%; 
		  height: 100%; 
		  border: 0;
		}
		
	}



/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1350px) {
		.video {
			@include span (10 of 12);
		}	
	}

	@media (max-width: 1024px) {
		.video {
			@include span (full);
		}
	}